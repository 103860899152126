/**
 * BxSlider v4.0 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2012
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */


/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
	position: relative;
	width:100%;
	margin: 0 auto 55px;
	padding: 0;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	padding: 0 9px;
	margin-left: -9px;
	z-index: 3;
	*zoom: 1;
}
.slider .bx-wrapper:after {
	content:'';
	display: block;
	position: absolute;
	width: 100%;
	height: 22px;
	background: url(../i/big-shadow.png) center top no-repeat;
	-webkit-background-size: 100%;
	background-size: 100%;
	bottom: -22px;
	left: 0;
}

.bx-wrapper img {
	min-width: 100%;
	height: 100% !important;
	display: block;
}

.bx-carousel:after {
	display: none;
}



/** THEME
===================================*/

.slider .bx-wrapper .bx-viewport {
	overflow: hidden;
	height: 356px !important;
	overflow:hidden!important;
	
}

.carousel .bx-wrapper .bx-viewport {
	overflow: visible !important;
	
}

.carousel .bx-wrapper:before,
.carousel .bx-wrapper:after {
	content:'';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	background-color: #e4e4e4;
	opacity: 0.7;
	z-index: 5;
}
.carousel .bx-wrapper:before{left: -100%;}
.carousel .bx-wrapper:after {left: 100%;}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: 15px;
	width: 100%;
	z-index:99;
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 50px;
	/* background: url(images/bx_loader.gif) center center no-repeat #fff; */
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

/* PAGER */

.bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-family: Arial;
	font-weight: bold;
	color: #666;
	padding-top: 20px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
	background: rgba(255,255,255,0.4);
	text-indent: -9999px;
	display: block;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	outline: 0;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
	background: #fff;
}


/* DIRECTION CONTROLS (NEXT / PREV) */

.slider .bx-wrapper .bx-prev {
	left: 0px;
	background: url(../i/controls.png) no-repeat 0 0px;
}
.slider .bx-wrapper .bx-prev:hover {
	background-position: 0 -60px;	
}


.slider .bx-wrapper .bx-next {
	right: 0px;
	background: url(../i/controls.png) no-repeat -50px 0px;
}
.slider .bx-wrapper .bx-next:hover {
	background-position: -50px -60px;	
}

.slider .bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
	margin-top: -37px;
	outline: 0;
	width: 50px;
	height: 59px;
	text-indent: -9999px;
	z-index: 9;
	-webkit-transition: 0s !important;
	-moz-transition: 0s !important;
	-o-transition: 0s !important;
	transition: 0s !important;
}

.carousel .bx-wrapper .bx-prev {
	left: -80px;
	background: url(../i/controls2.png) no-repeat 0 0px;
}

.carousel .bx-wrapper .bx-next {
	right: -80px;
	background: url(../i/controls2.png) no-repeat -35px 0px;
}

.carousel .bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
	margin-top: -33px;
	outline: 0;
	width: 35px;
	height: 66px;
	text-indent: -9999px;
	z-index: 9;
}


.bx-wrapper .bx-controls-direction a.disabled {
	display: none;
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
	text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
	display: block;
	text-indent: -9999px;
	width: 10px;
	height: 11px;
	outline: 0;
	/* background: url(images/controls.png) -86px -11px no-repeat; */
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
	background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
	display: block;
	text-indent: -9999px;
	width: 9px;
	height: 11px;
	outline: 0;
	/* background: url(images/controls.png) -86px -44px no-repeat; */
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
	background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}

/* IMAGE CAPTIONS */

.slider  .bx-wrapper .caption{
	position: absolute;
	top: 37px;
	right: 0;
	width: 42.7%;
	height: 283px;
	background-color: rgba(0,0,0,0.70);
	padding: 26px 80px 26px 31px;
}

.slider .bx-wrapper .caption h2{
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 36px;
	line-height: 40px;
	color: #fff;
	letter-spacing: -1px;
	margin: 0;
}

.slider  .bx-wrapper .caption .text{
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	color: #fff;
	font-size: 16px;
	line-height: 24px;
	margin: 8px 0;
}
.slider .bx-wrapper .caption .btn{
	margin: 13px 7px 0;
}


